import React, { FC } from 'react';
import SimpleBar from 'simplebar-react';

import Activity from 'components/Activity';
import { ActivityListType } from 'components/Pages/Activity/Content/ProgramsActivity/types';
import ScheduledActivitiesWrapper from './styles';

interface Props {
  list?: ActivityListType[];
}

const ScheduledActivitiesList: FC<Props> = ({ list }) =>
  list ? (
    <SimpleBar>
      <ScheduledActivitiesWrapper>
        {list?.map(({ date, activities }) =>
          activities.map((item, idx) => (
            <Activity
              key={`${idx}-${item.id}`}
              id={item.id}
              type={item.type}
              session={item.session}
              trackSessionId={item.trackSessionId}
              program={item.program}
              completedAt={date}
              ease={item.ease}
              quality={item.quality}
              exercisesCount={item.exercisesCount}
              timeLength={item.timeLength}
              compact
            />
          )),
        )}
      </ScheduledActivitiesWrapper>
    </SimpleBar>
  ) : null;

export default React.memo(ScheduledActivitiesList);
